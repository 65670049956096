export const QUERY_KEYS = {
  customers: 'CUSTOMERS',
  categories: 'CATEGORIES',
  products: 'PRODUCTS',
  administrators: 'ADMINISTRATORS',
  breadcrumbs: 'BREADCRUMBS',
  orders: 'ORDERS',
  realisations: 'REALISATIONS',
  tutorials: 'TUTORIALS',
  dashboard_stats: 'DASHBOARD_STATS',
};

export const MODALS = {
  ADD_LINKED_TUTORIAL: 'ADD_LINKED_TUTORIAL',
  ADD_RELATED_PRODUCTS: 'ADD_RELATED_PRODUCTS',
  ORDER_DETAILS: 'ORDER_DETAILS',

  ADD_TUTORIAL: 'ADD_TUTORIAL',
  ADD_CUSTOMER: 'ADD_CUSTOMER',
  ADD_CATEGORY: 'ADD_CATEGORY',
  ADD_PRODUCT: 'ADD_PRODUCT',
  ADD_VARIANT: 'ADD_VARIANT',
  ADD_ADMIN: 'ADD_ADMINISTRATOR',
  DELETE_CUSTOMER: 'DELETE_CUSTOMER',
  DELETE_ADMIN: 'DELETE_ADMIN',
  DELETE_CATEGORY: 'DELETE_CATEGORY',
  DELETE_PRODUCT: 'DELETE_PRODUCT',
  DELETE_TUTORIAL: 'DELETE_TUTORIAL',
  DELETE_REALISATION: 'DELETE_REALISATION',
  EDIT_CUSTOMER: 'EDIT_CUSTOMER',
  EDIT_CATEGORY: 'EDIT_CATEGORY',
  EDIT_ADMIN: 'EDIT_ADMIN',
  EDIT_PRODUCT: 'EDIT_PRODUCT',
  EDIT_TUTORIAL: 'EDIT_TUTORIAL',
  EDIT_VARIANT: 'EDIT_VARIANT',
  EDIT_STOCK: 'EDIT_STOCK',

  REMOVE_VARIANT: 'REMOVE_VARIANT',
};

export const stateColorMap: Record<string, string> = {
  BLOCKED: '#ffbbbb',
  ACTIVE: '#b1e9c8',
  TO_ACTIVATE: '#ffad5d',
  PENDING: '#ffad5d', // Example color for PENDING
  PAYMENT_REQUIRED: '#ffbbbb', // Example color for PAYMENT_REQUIRED
  CONFIRMED: '#5bc0de', // Example color for CONFIRMED
  SHIPPED: '#5cb85c', // Example color for SHIPPED
  DELIVERED: '#0275d8', // Example color for DELIVERED
  CANCELED: '#ffbbbb', // Example color for CANCELED
  UNKNOWN: '#eee', // Example color for UNKNOWN
  PAID: '#b1e9c8', // Example color for PAID
  OPEN: '#ffad5d', // Example color for OPEN (same as PENDING)
  PAYMENT_FAILED: '#ffbbbb', // Example color for PAYMENT_FAILED
  EXPIRED: '#6c757d', // Example color for EXPIRED
  SETTLED: '#5bc0de', // Example color for SETTLED
  CHARGEBACK: '#ffbbbb', // Example color for CHARGEBACK
  REFUNDED: '#0275d8', // Example color for REFUNDED
  REFUND_PROCESSING: '#f0ad4e', // Example color for REFUND_PROCESSING
  PARTIALLY_REFUNDED: '#5bc0de', // Example color for PARTIALLY_REFUNDED
  AUTHORISED: '#b1e9c8', // Example color for AUTHORISED
};

export const stateTranslationMap: Record<string, string> = {
  PENDING: 'In afwachting',
  PAYMENT_REQUIRED: 'Betaling vereist',
  CONFIRMED: 'Bevestigd',
  SHIPPED: 'Verzonden',
  DELIVERED: 'Geleverd',
  CANCELED: 'Geannuleerd',
  UNKNOWN: 'Onbekend',
  PAID: 'Betaald',
  OPEN: 'In afwachting',
  PAYMENT_FAILED: 'Betaling mislukt',
  EXPIRED: 'Verlopen',
  BLOCKED: 'Geblokkeerd',
  SETTLED: 'Settled',
  CHARGEBACK: 'Chargeback',
  REFUNDED: 'Terugbetaald',
  REFUND_PROCESSING: 'Refund processing',
  PARTIALLY_REFUNDED: 'Gedeeltelijk terugbetaald',
  AUTHORISED: 'Geautoriseerd',
};
