import { Edit, Info, Plus, Star, Trash } from 'lucide-react';
import { useState } from 'react';

import { useModalStore } from '../../stores/modalStore';
import { useToastStore } from '../../stores/toastStore';
import { MODALS } from '../constants';
import { Button } from '../elements';
import InputError from '../elements/InputError';

import Images from './_images';
import LinkedTutorials from './_linkedTutorials';
import RelatedProducts from './_relatedProducts';
import { Product, Variant } from './dto/product.dto';
import { useProductForm } from './hooks';
import AddLinkedTutorialModal from './modal/AddLinkedTutorialModal';
import AddRelatedProductModal from './modal/AddRelatedProductModal';
import AddVariantModal from './modal/AddVariantModal';
import EditVariantModal from './modal/EditVariantModal';
import RemoveVariantModal from './modal/RemoveVariantModal';


type VariantContainerProps = {
  product?: Product,
  variant: Variant
  setSelectVariant: (variant: Variant) => void;
  addVariant: (variant: Variant) => void;
  editing: boolean;
}

const VariantContainer = ({
  product,
  variant,
  setSelectVariant,
  editing
}: VariantContainerProps) => {
  const modalStore = useModalStore();

  const openDeleteVariant = () => {
    setSelectVariant(variant);
    modalStore.openModal(MODALS.REMOVE_VARIANT);
  };

  const openEditVariant = () => {
    setSelectVariant(variant);
    modalStore.openModal(MODALS.EDIT_VARIANT);
  }

  return (
    <li
      key={variant.id}
      className="bg-primary p-4 rounded-lg relative cursor-pointer bg-no-repeat bg-cover min-h-[200px] mb-4"
    >
      {
        variant.favourite && (
          <div className="absolute right-6 top-6 bg-primary rounded-[50%] p-2">
            <Star className="text-yellow-500" />
          </div>
        )
      }

      <div className="grid grid-cols-1 gap-4">
        <div className="w-full h-[245px] mb-4">
          <img
            className="w-full h-[100%] object-cover rounded-lg"
            src={
              editing ? `/api/v1/variants/${variant.image_to_display}/image` : variant.image_src
            }
            alt=""
          />
        </div>

        <div className="flex flex-col sm:p-4">
          <span className="text block font-semibold mb-4">
            € {parseInt(`${variant.price_inc_vat}`).toFixed(2)} incl. {variant.vat}%
          </span>
          <span className="text block font-semibold mb-4">
            Voorraad: {variant.stock}
          </span>

          <div className="text-xs flex gap-1 flex-col text-[#8c8c8c]">
            {
              product?.type === 'TABLE' && 'color' in variant.attributes && (
                <>
                  <span>hoogte: {variant.attributes.height}</span>
                  <span>breedte: {variant.attributes.width}</span>
                  <span>lengte: {variant.attributes.depth}</span>
                  <span>afwerking: {variant.attributes.finish}</span>
                  <span className="flex gap-4">
                    kleur:
                    <div
                      className="w-[20px] h-[20px] rounded-[50%] border-2 border-solid border-[#000]"
                      style={{
                        backgroundColor:
                          variant.attributes.color,
                      }}
                    />
                  </span>
                </>
              )
            }

            <span>Levertermijn: {variant.delivery_time}</span>
          </div>

          <div className="flex gap-2 w-full mt-4" onClick={(ev) => ev.stopPropagation()}>
            <Button
              icon={<Trash />}
              variant="delete"
              onClick={openDeleteVariant}
              className="justify-center"
            />

            <Button
              icon={<Edit />}
              variant="secondary"
              onClick={openEditVariant}
              className="justify-center"
            />
          </div>
        </div>
      </div>
    </li>
  )
}

type ProductDetailProps = {
  product?: Product;
  productForm: ReturnType<typeof useProductForm>;
  editing: boolean;
};

const ProductContainer = ({ product, productForm, editing }: ProductDetailProps) => {
  const modalStore = useModalStore();
  const toastStore = useToastStore();
  const [selectedVariant, setSelectedVariant] = useState<Variant | null>(null);

  const {
    register,
    formState: { errors },
    getValues,
    addVariant,
    removeVariant,
    updateVariant,
    addRelatedProduct,
    removeRelatedProduct,
    removeLinkedTutorial,
    addImage,
    removeImage,
    addLinkedTutorial
  } = productForm;

  const openAddVariantModal = () => {
    modalStore.openModal(MODALS.ADD_VARIANT);
  };

  const openAddProductModal = () => {
    modalStore.openModal(MODALS.ADD_RELATED_PRODUCTS);
  }

  const openAddTutorialModal = () => {
    modalStore.openModal(MODALS.ADD_LINKED_TUTORIAL);
  }

  const handleDeleteImage = (imagePath: string, imageId: string) => {
    const variant = product?.variants.find(variant => variant.image_to_display === imageId);
    if (!variant && product) {
      removeImage(imagePath);
      product.images = product?.images.filter(img => img.path !== imagePath)
      return
    }

    toastStore.errorToast('Deze afbeelding is gekoppeld aan een variant, verwijder eerst de variant');
  }

  const { variants, id, linked_tutorials, related_products } = getValues();

  const newProduct = getValues();
  return (
    <>
      {
        editing && product && (
          <>
            <RemoveVariantModal
              removeVariant={removeVariant}
              variant={selectedVariant}
            />
            <AddVariantModal
              addVariant={addVariant}
              product={product}
              editing
            />
            <EditVariantModal editVariant={updateVariant} variant={selectedVariant} product={product} editing />
            <AddLinkedTutorialModal
              product={product}
              linkedTutorials={linked_tutorials}
              addLinkedTutorial={addLinkedTutorial}
              editing
            />
            <AddRelatedProductModal
              product={product}
              productId={product.id}
              editing
              addRelatedProduct={addRelatedProduct}
            />
          </>
        )
      }

      {
        !editing && (
          <>
            <AddVariantModal
              addVariant={addVariant}
              product={newProduct}
              editing={false}
            />
            <RemoveVariantModal
              removeVariant={removeVariant}
              variant={selectedVariant}
            />
            <EditVariantModal
              editVariant={updateVariant}
              variant={selectedVariant}
              product={newProduct}
              editing={false}
            />
            <AddLinkedTutorialModal
              product={newProduct}
              linkedTutorials={linked_tutorials}
              addLinkedTutorial={addLinkedTutorial}
              editing={false}
            />
            <AddRelatedProductModal
              productId={id}
              product={newProduct}
              editing={false}
              addRelatedProduct={addRelatedProduct}
            />
          </>
        )
      }



      <div className="bg-lighter p-4 rounded-lg mb-4">
        <div className="flex justify-start items-center mb-8 gap-4">
          <Info />
          <h2 className="text-xl font-bold">
            Product gegevens
          </h2>
        </div>

        <form>
          <div className="flex flex-col mb-4">
            <label className="mb-2 font-bold">Titel</label>
            <input
              className="p-4 rounded-lg bg-primary"
              type="text"
              placeholder="Mortex salontafel 2.0"
              {...register('name')}
            />
            {errors.name && (
              <InputError text={errors.name.message} />
            )}
          </div>
          <div className="flex flex-col mb-4">
            <label className="mb-2 font-bold">Type</label>
            <select
              disabled={editing}
              className="p-4 rounded-lg  bg-primary"
              {...register('type')}>
              <option value="TABLE">Tafel</option>
              <option value="STANDALONE">Materiaal</option>
            </select>
          </div>

          <div className="flex flex-col">
            <label className="mb-2 font-bold">Omschrijving</label>
            <textarea
              {...register('description')}
              className="p-4 rounded-lg min-h-48  bg-primary"
              placeholder="Prachtige salon tafel met Mortex afwerking."
            />

            {errors.description && (
              <InputError text={errors.description.message} />
            )}
          </div>
        </form>
      </div>

      <div className="bg-lighter p-4 rounded-lg mb-4">
        <div className="flex justify-start items-center mb-8 gap-4">
          <Info />
          <h2 className="text-xl font-bold">
            Afbeeldingen
          </h2>
        </div>


        {
          editing && product && (
            <Images
              product={product}
              images={product?.images ?? []}
              newProduct={false}
              addImage={addImage}
              deleteImage={handleDeleteImage}
            />
          )
        }

        {
          !editing && (
            <Images
              product={newProduct}
              images={newProduct.images}
              newProduct
              addImage={addImage}
              deleteImage={handleDeleteImage}
            />
          )
        }
      </div>

      <div className="bg-lighter p-4 rounded-lg mb-4">
        <div className="flex justify-between mb-8 gap-4 flex-col items-start sm:flex-row">
          <div className="flex justify-start items-center gap-4">
            <Info />
            <h2 className="text-xl font-bold">
              Varianten
            </h2>
          </div>

          <Button
            text="Nieuwe variant"
            icon={<Plus />}
            variant="tertiary"
            className="md:w-max"
            onClick={openAddVariantModal}
          />
        </div>
        <div>

          {
            variants.length === 0 && (
              <div className="p-4 bg-primary">
                Momenteel zijn er nog geen varianten gekoppeld aan dit product.
              </div>
            )
          }

          {
            variants.length > 0 && (
              <ul className="grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3">
                {
                  variants.map(variant => (
                    <VariantContainer
                      product={product}
                      editing={editing}
                      key={variant.id}
                      addVariant={addVariant}
                      variant={variant}
                      setSelectVariant={setSelectedVariant}
                    />
                  ))
                }
              </ul>
            )
          }

        </div>
      </div>

      <div className="bg-lighter p-4 rounded-lg mb-4">
        <div className="flex flex-col justify-between items-start mb-8 gap-4 sm:flex-row">
          <div className="flex justify-start items-center gap-4">
            <Info />
            <h2 className="text-xl font-bold">
              Gekoppelde tutorials
            </h2>
          </div>


          <Button
            text="Koppel tutorial"
            icon={<Plus />}
            variant="tertiary"
            className="md:w-max"
            onClick={openAddTutorialModal}
          />
        </div>



        <LinkedTutorials
          linkedTutorials={linked_tutorials}
          removeLinkedTutorial={removeLinkedTutorial}
        />
      </div>

      <div className="bg-lighter p-4 rounded-lg mb-4">
        <div className="flex justify-between items-start mb-8 gap-4 flex-col sm:flex-row">
          <div className="flex justify-start items-center gap-4">
            <Info />
            <h2 className="text-xl font-bold">
              Gerelateerde producten
            </h2>
          </div>

          <Button
            text="Link product"
            icon={<Plus />}
            variant="tertiary"
            className="md:w-max"
            onClick={openAddProductModal}
          />
        </div>


        <RelatedProducts
          productId={id}
          relatedProducts={related_products ?? []}
          removeRelatedProduct={removeRelatedProduct}
        />
      </div>
    </>
  );
};

export default ProductContainer;
